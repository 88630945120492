
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$viaxlab-primary: mat-palette($mat-indigo);
$viaxlab-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$viaxlab-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$viaxlab-theme: mat-light-theme((
  color: (
    primary: $viaxlab-primary,
    accent: $viaxlab-accent,
    warn: $viaxlab-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($viaxlab-theme);

// -----------------------------------------------------------------------------------------------------
// @ You can use this file to import styles from third party libraries.
//
// @ It's important to put them here because anything imported from this file can be overridden by
//   Treo which allows having out-of-the-box support for certain libraries. They can also be
//   overridden from 'styles.scss' file which allows you to override and make any third party library
//   that Treo doesn't support out-of-the-box visually compatible with your application.
// -----------------------------------------------------------------------------------------------------

// Perfect scrollbar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Quill
@import '~quill/dist/quill.snow.css';
