// -----------------------------------------------------------------------------------------------------
// @ You can use this file to import custom styles.
//
// @ Styles from this file will override anything from 'vendors.scss' file allowing customizations and
//  modifications of third party libraries.
// -----------------------------------------------------------------------------------------------------

@import 'treo';
@import '../app/shared/styles/colors.scss';
@import '../@treo/styles/main.scss';

///---------- General ----------///

html,
body {
  height: 100vh;
  line-height: unset;
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
}

.main .header {
  padding: 35px;
}

futuristic-layout > .wrapper > .content {
  overflow-y: auto;
}

.mat-cell,
.mat-footer-cell {
  font-size: 0.84rem !important;
}

.no-padding {
  &.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper {
    margin-bottom: 0 !important;
  }
}

.text-light-grey {
  color: #98a6ba !important;
}

.text-grey {
  color: #585f6a !important;
}

.w-fit {
  width: fit-content;
}

li.header-list-item,
#header-info-section {
  background: #fbfdfe !important;
  color: #64748b;
  font-weight: bold;
}

@media only screen and (min-width: 1024px) {
  .wrapper > .header {
    display: none !important;
  }
}

.full-height {
  flex: 1;
  height: 100%;
  min-height: 0;

  .mat-tab-body-wrapper {
    height: 100%;
  }
}

.small-paginator {
  & .mat-paginator-outer-container {
    & .mat-paginator-container {
      justify-content: space-between;

      & .mat-paginator-page-size {
        margin: 0;
      }
    }
  }
}

// Buttons

.mat-button .mat-icon,
.mat-flat-button .mat-icon,
.mat-stroked-button .mat-icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  font-size: 20px;
  line-height: 20px;
}

.treo-theme-light .mat-button,
.treo-theme-light .mat-flat-button,
.treo-theme-light .mat-stroked-button,
.file-upload--button,
.file-upload--container {
  &.btn-secondary {
    color: #64748b;
    background: #f1f5f9;
    border-color: transparent;
  }
}

.file-upload--container input {
  display: none;
}

.file-upload--button {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;

  .mat-icon {
    height: 38px;
    width: 38px;
    font-size: 38px;
  }
  span {
    font-weight: 500;
  }
}

.file-upload--button:hover,
.file-upload--container:hover {
  background-color: #ebf0f6;
  transition: 0.2s all;
}

///---------- Modal styles and table ----------///

.cdk-overlay-pane.confirm-dialog-dialog {
  width: auto !important;
  height: auto !important;
  max-height: 100%;
}

.mat-dialog-container {
  padding: 0 !important;
}

.select-provider-dialog table {
  width: 100%;
  min-width: 640px;
  table-layout: fixed;

  th {
    position: initial !important;
  }
}

.cdk-overlay-pane {
  height: auto !important;
}

.mat-dialog-container {
  overflow: hidden;
  .main-content {
    overflow: hidden;
  }
}
.mat-dialog-content {
  max-height: initial;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.8rem;
  border-top: 1px solid #e2e8f0;
}
.mat-dialog-container {
  .content,
  #main-section {
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
/// Responsive
@media screen and (max-width: 500px) {
  .cdk-overlay-pane.modal-container {
    max-width: 95vw !important;
  }
  select-provider-dialog {
    max-height: 100vh !important;
  }
  .mat-dialog-container {
    .content,
    #main-section {
      padding: 1rem;
      max-height: 74vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .table-wrapper {
      width: 95vw;
      overflow: auto;
    }
  }
}

// Table styles (modal)
.select-provider-modal {
  tr.mat-row,
  tr.mat-footer-row {
    height: 50px !important;
  }
}

.trip-form-modal {
  display: flex;
  flex-direction: column;
  min-width: 640px;
  width: 100%;

  @include treo-breakpoint('lt-md') {
    min-width: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 32px;
    height: 64px;
    min-height: 64px;
    max-height: 64px;

    .title {
      font-size: 16px;
    }
  }

  .content {
    padding: 1.5rem 1.9rem 0rem 1.9rem;
  }

  .buttons {
    padding: 0.8rem;
  }
}

@include treo-theme {
  $primary: map-get($theme, primary);

  .trip-form-modal {
    .header {
      background: map-get($primary, default);
      color: map-get($primary, default-contrast);

      .mat-icon {
        color: map-get($primary, default-contrast);
      }
    }
  }
}

///---------- Input Styles -----------///

.mat-form-field.mat-form-field-appearance-fill
  .mat-form-field-wrapper
  .mat-form-field-flex
  .mat-form-field-infix
  .mat-input-element {
  padding: 10px 0;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
  min-height: 40px;
}

.selectProvider {
  > div {
    align-items: center;
  }
  .selectProvider--button {
    margin-top: 0.375rem;
  }
  .selectProvider--label {
    font-weight: 500;
  }
}

// File uploader

.file-upload--container {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;

  .mat-icon {
    height: 38px;
    width: 38px;
    font-size: 38px;
  }
  span {
    font-weight: 500;
  }
}

///----------- Table styles  -----------///
tr.mat-header-row {
  height: 40px !important;
}

.table-wrapper table tbody tr {
  height: 54px;
}
.mat-paginator .mat-paginator-container {
  justify-content: center;
  
  padding: 0 16px;
}
.cdk-column-actions,
.cdk-column-delete {
  text-align: end;
}
.mat-table.cdk-table {
  height: auto;
}

@media screen and (max-width: 600px) {
  .main .table-wrapper {
    height: calc(100% - 115px);
  }
}

///----------- Image thumbnails ------------///
.thumbnail {
  position: relative;
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.thumbnail img {
  max-width: 200px;
  width: auto;
  height: 100px;
  max-height: 100px;
  border: 1px solid #aba7a73d;
  object-fit: cover;
  border-radius: 6px;
}

.thumbnail button {
  position: absolute;
  top: -8px;
  right: -14px;
  height: 27px;
  width: 27px;
  border-radius: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #6c7889;

  .mat-icon {
    height: 17px;
    width: 17px;
    min-width: 0;
    min-height: 0;
    color: #f1f7ff;
  }
}

///---------- Header styles -----------///
.header {
  border-bottom: 1px solid #e2e8f0;
}

.app-full-bleed-dialog .mat-dialog-container {
  padding: 0;
}

.modify-code-panel {
  height: 100% !important;
  padding: 0px;
}

@media screen and (max-width: 600px) {
  .info-section {
    align-items: start !important;
    height: 140px;
    flex-direction: column;
    padding: 1.5rem 1rem !important;

    #search-trip,
    .title > div {
      width: 100%;
      margin-top: 0.7rem;
    }
  }
  .main .header {
    padding: 1.5rem 1rem;
  }
}

///------ Placeholder styles ------///
.placeholderSection {
  font-size: 1.26rem;
  color: #bec9dc;
  .mat-icon {
    width: 5.7rem;
    height: 5.7rem;
    color: #bec9dc;
  }
}

// -----------------------------------------------------------------------------------------------------
//// Responsive for tabs on trips and groups (temporary fix) /////

@media screen and (max-width: 740px) {
  .tabs--responsive {
    .content-layout.fullwidth-tabs-navigation-inner-scroll {
      position: initial;
      overflow: auto;
      height: 100%;
      width: 100vw;
      display: flex;
      flex-grow: 1;
    }

    #main-trip-groups-section,
    #main-section, #actions-section {
      flex-direction: column;
    }
    #select-group-section,
    #select-day-section > div {
      padding: 1rem;
    }
    #passenger-drawer {
      position: initial;
    }
    .passenger-list-info {
      min-width: 650px;
    }
    passenger-card .card-section, #activities-list-section .list, #activities-list-section .activity-card-item {
      width: 700px!important;
    }
    mat-drawer {
      width: 100% !important;
      position: absolute !important;
      top: 0;
    }
    .mat-paginator-container span.mat-button-wrapper {
      z-index: unset !important;
    }
    #groups-section,
    #main-section #group-section,
    #activity-container #main-section #activities-section,
    #activity-section {
      flex: 0 0 16rem !important;
      border-bottom: 2px solid #e2e8f0;
    }
    #main-section #list-wrapper,
    #main-section #passenger-drawer #passenger-drawer-content #passenger-section #passenger-list-wrapper {
      display: flex !important;
      flex-direction: column !important;
      height: 13rem;
      overflow-y: auto;
    }
    futuristic-layout > .wrapper > .content > *:not(router-outlet) {
      display: initial;
    }
  }
}

.iti__selected-flag {
  background: #ffffff !important;
  padding: 0px !important;

  #phone {
    padding-left: 68px !important;
  }
}

.iti__selected-dial-code {
  font-family: "Avenir Medium";
  font-size: 15px;
  color: #000000;
}

:host::ng-deep ul.iti__country-list {
  z-index: 1000;
}

// -----------------------------------------------------------------------------------------------------

mat-drawer-container, .mat-drawer-container {
  z-index: 2 !important;
}

.timepicker__header {
  background-color: #4a6eb4 !important;
}

.timepicker-button {
  color: #4a6eb4 !important;
}

.clock-face__number .active{
  background-color: #4a6eb4 !important;
}

// .clock-face__clock-hand .clock-face__clock-hand_minute {
.clock-face__clock-hand {
  background-color: #4a6eb4 !important;

  &:before {
    border: 4px solid #4a6eb4 !important;
  }
}

.user-already-exists-snack-bar button {
    color: #ffffff !important;
    background-color: #2057b1 !important;
}

[secondary-btn], .secondary-btn {
  display: flex;
  align-items: center;
  
  padding: 4px 8px;
  border-radius: 4px;

  background: transparent;

  font-size: 12px;
  color: #2058B1;

  transition: .3s;
  cursor: pointer;

  .mat-icon {
    font-size: 16px;
    color: currentColor;
  }

  &:hover {
    background: #2058B125;
  }

  &.filled {
    padding: 2px 8px;
    background-color: #457cce;
    color: #FFFF;

    &:has(mat-icon) {
      padding: 2px;
      padding-right: 8px;
    }

    &:hover {
      background: #2f63b1;
    }
  }
}

.bg-white-25 {
  &:hover {
    background: #FFFFFF25;
  }
}

[label], .label {
  font-weight: 500;
  font-size: 0.875rem;;
}

angular-editor {
  ul > li {
    list-style: disc;
  }

  ol > li {
    list-style: decimal;
  }

  ul, ol {
    margin-left: 12px;
  }

  .angular-editor-textarea {
    border-radius: 6px;
  }
  
  .angular-editor-toolbar {
    border-radius: 6px;
    
    font-family: 'Inter', sans-serif;

    .angular-editor-button {
      color: #444444;
    }
    
    .angular-editor-toolbar-set, .ae-picker-label {
      border-radius: 4px;
    }
    
    .angular-editor-button:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    
    .angular-editor-button:last-of-type {
      border-radius: 0 4px 4px 0;
    }
    
    .angular-editor-button:only-child {
      border-radius: 4px;
    }
    
    .angular-editor-button.active {
      background: #2057b115 !important;
    }
    
    .ae-font .ae-picker-options .ae-picker-item {
      @extend .mat-menu-item;
      
      &.focused {
        background-color: rgba(151, 166, 186, 0.12);

        .angular-editor-button {
          color: #2057b1;
        }
      }
    }
  }
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-10 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

.text-balance {
  text-wrap: balance;
}

.w-fill-available {
  width: -webkit-fill-available;
}

.top-1 {
  top: 8px;
}

.bottom-1 {
  bottom: 8px;
}

.left-1 {
  left: 8px;
}

.right-1 {
  right: 8px;
}

.notification-snackbar {
  background-color: #ecf1f8 !important;
  border-left: 6px solid #2058b1;
}

.bg-amber-100 {
  background-color: #FFF8E1 !important;
}

.bg-amber-500 {
  background-color: #FFC107 !important;
}

.text-amber-600 {
  color: #FFA000 !important;
}

.thin-border {
  border: .5px solid #3183ed50;
}

.thin-border-bottom {
  border-bottom: .5px solid #3183ed50;
}

.bg-secondary-10 {
  background-color: #3183ed10;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}